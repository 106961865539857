<template>
  <div>
    <Modal :options="{width: '70vw',close: true}" @close="$emit('close')">
      <template v-if="!solicitud">
        <div class="title">Solicitud ...</div>
        <div class="body">
          <div class="row">
            <div class="col-sm-12 text-center">Cargando solicitud...</div>
          </div>
        </div>
      </template>
      <template v-else>
      <div class="title">Solicitud {{ solicitud.solicitud_id }}</div>
      <div class="body">
        <div class="row">
          <div class="col-md-12 col-xl-8">
            <div class="datos_items datos_items_pequenios">
              <div class="titulo">Alianza</div>
              <div class="valor">{{ solicitud.alianza ? solicitud.alianza.nombre : 'Sin alianza' }}</div>
            </div>
            <div class="datos_items datos_items_pequenios mb-3">
              <div class="titulo">Estatus</div>
              <div class="valor">{{ solicitud.estatus }}</div>
            </div>

            <DataGrid :configuracion="datagrid_config" :data="solicitud.observaciones">

            </DataGrid>
          </div>
          <div class="col-md-12 col-xl-4">
            <div class="row">
              <div class="col-sm-12 text-center">Estatus de carga de datos</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Analytic Point</div>
              <div class="valor">{{ solicitud.data && solicitud.data.analyticpoint ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Créditos</div>
              <div class="valor">{{ solicitud.data && solicitud.data.creditos ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Formalización</div>
              <div class="valor">{{ solicitud.data && solicitud.data.formalizacion ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Comité general</div>
              <div class="valor">{{ solicitud.data && solicitud.data.comite && solicitud.data.comite.general ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Comité raw</div>
              <div class="valor">{{ solicitud.data && solicitud.data.comite && solicitud.data.comite.raw ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Documentación</div>
              <div class="valor">{{ solicitud.data && solicitud.data.valija ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Scoring general</div>
              <div class="valor">{{ solicitud.data && solicitud.data.scoring && solicitud.data.scoring.all ? 'Cargado' : 'No cargado' }}</div>
            </div>
            <div class="datos_items">
              <div class="titulo">Scoring OCR</div>
              <div class="valor">{{ solicitud.data && solicitud.data.scoring &&solicitud.data.scoring.ocr ? 'Cargado' : 'No cargado' }}</div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="footer text-right">
        <button class="btn btn-primary mr-2" @click="procesar_solicitud">Volver a procesar</button>
        <button class="btn btn-primary mr-2" @click="procesar_solicitud(1)">Volver a procesar y notificar</button>
        <!-- <button class="btn btn-secondary">Activar</button> -->
      </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import DataGrid from '@/components/DataGridV3';

import api from '@/apps/fyc/api/solicitudes';

export default {
  components: {
    Modal, DataGrid
  },
  props: {
    solicitud_id: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      datagrid_config: {
        name: 'solicitudes',
        selector: false,
        show_search: false,
        cols: {
          usuario: 'Origen',
          observacion: 'Observación',
          created_at: 'Fecha',
        },
        mutators: {
          created_at(val,row,vue) {
            return vue.$moment(val).format('ddd DD MMM YYYY - HH:mm')
          }
        }
      },
      solicitud: null
    }
  },
  mounted() {
    this.obtener_solicitud();
  },
  methods: {
    async obtener_solicitud() {
      try {
        this.solicitud = (await api.obtener_solicitud(this.solicitud_id)).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async procesar_solicitud(notificar) {
      let id = this.solicitud_id;

      if (notificar)
        id += '?notificar=1';

      await api.procesar_solicitud(id);
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>
  .datos_items {
    .titulo, .valor {
      display: inline-block;
    }
    .titulo {
      width: 40%;
      background-color: hsl(0, 0%, 80%);
      padding: 7px 10px;
    }
    .valor {
      width: 60%;
      background-color: hsl(0, 0%, 90%);
      text-align: right;
      padding: 7px 10px;
    }
  }

  .datos_items_pequenios {
    .titulo {
      width: 20%;
    }
    .valor {
      width: 80%;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 992px) {
    .datos_items {
      .titulo {
        width: 50%;
      }
      .valor {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .datos_items {
      .titulo {
        width: 50%;
      }
      .valor {
        width: 50%;
      }
    }
  }
</style>